import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Content from "../components/contentEn";
import Footer from "../components/footer";
import { menuItemsEn } from "../constants/menuItems";
import ClientOnly from "../components/client-only";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageEnQuery {
      site {
        siteMetadata {
          en {
            title
          }
        }
      }
    }
  `);

  const { title } = data.site.siteMetadata.en;

  return (
    <Layout>
      <SEO
        title="React and Node.js development in Busan South Korea"
        description="Busan IT Automation provides React, Node.js, mobile, iOS, Android development, DevOps and Data Analysis in South Korea"
      />
      <ClientOnly>
        <Header title={title} languageEn={true} drawerMenuItems={menuItemsEn} />
      </ClientOnly>
      <ClientOnly>
        <Content title={title} />
      </ClientOnly>
      <ClientOnly>
        <Footer />
      </ClientOnly>
    </Layout>
  );
};

export default IndexPage;
